import { CheckCircleTwoTone, QuestionCircleOutlined, WarningTwoTone } from '@ant-design/icons'
import React from 'react'
import * as libCon from "../community-hats-js-library/Constants"
import Title from 'antd/es/typography/Title';
import { Spin } from 'antd';
import { RefStoredValue } from '../hooks/GeneralStoreHooks';

const DatabaseStatus = ({ title, status }) => {

    const Symbol = ({ status }) => {
        switch (status) {
            case libCon.LOADING:
                return (<Spin />)
            case libCon.OK:
                return (<CheckCircleTwoTone twoToneColor="#52c41a" />)
            case libCon.ERROR:
                return (<WarningTwoTone twoToneColor="#e91822" />)
            default:
                return (<QuestionCircleOutlined />);
        }
    }


    return (
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            <Title level={5}>{title}</Title>
            <Symbol status={status} />
        </div>
    )

}

function LoadingDatasets() {


    // Participants
    const statusParticipants = RefStoredValue(libCon.STATUS_DATASET_PARTICIPANTS)

    // Houses
    const statusHouses = RefStoredValue(libCon.STATUS_DATASET_HOUSES)

    // Emails
    const statusEmails = RefStoredValue(libCon.STATUS_DATASET_EMAILS)

    // Sensors
    const statusSensors = RefStoredValue(libCon.STATUS_DATASET_SENSORS)

    // Sensor Placement
    const statusSensorPlacement = RefStoredValue(libCon.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT)

    // Wearables
    const statusWearables = RefStoredValue(libCon.STATUS_DATASET_WEARABLES)

    // Wearable Placement
    const statusWearablesPlacement = RefStoredValue(libCon.STATUS_DATASET_WEARABLE_PLACEMENT)

    // Phones
    const statusPhones = RefStoredValue(libCon.STATUS_DATASET_PHONES)

    // Phone Placement
    const statusPhonePlacement = RefStoredValue(libCon.STATUS_DATASET_PHONE_PLACEMENT)

    // Received Sensor Files
    const statusReceivedSensorFiles = RefStoredValue(libCon.STATUS_DATASET_RECEIVED_SENSOR_FILES)

    // Received Sensor Files
    const statusIssues = RefStoredValue(libCon.STATUS_DATASET_ISSUES)

    // Participant Placements
    const statusParticipantPlacement = RefStoredValue(libCon.STATUS_DATASET_PARTICIPANT_PLACEMENT)


    return (
        <div className='verticalSection'>
            <div className='horizontalSection'>
                <DatabaseStatus title={"Participants"} status={statusParticipants} />
                <DatabaseStatus title={"Houses"} status={statusHouses} />
                <DatabaseStatus title={"Sensors"} status={statusSensors} />
                <DatabaseStatus title={"SensorPlacement"} status={statusSensorPlacement} />
                <DatabaseStatus title={"Wearables"} status={statusWearables} />
                <DatabaseStatus title={"Emails"} status={statusEmails} />


            </div>
            <div className='horizontalSection'>
                <DatabaseStatus title={"WearablesPlacement"} status={statusWearablesPlacement} />
                <DatabaseStatus title={"Participant Placements"} status={statusParticipantPlacement} />
                <DatabaseStatus title={"Phones"} status={statusPhones} />
                <DatabaseStatus title={"PhonePlacement"} status={statusPhonePlacement} />
                <DatabaseStatus title={"ReceivedSensorFiles"} status={statusReceivedSensorFiles} />
                <DatabaseStatus title={"Issues"} status={statusIssues} />
            </div>
        </div>
    )
}

export default LoadingDatasets