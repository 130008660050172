import React, { useEffect, useState } from 'react'
import { RefActiveParticipantsWithAllPlacements, refreshAllDatasets } from '../hooks/DatasetHooks'
import { updateSinglePhone, updateAccountWithLastSynched, updateSingleSensorFiles, logBatteryChange, updateSingleParticipant, markIssueAsReadyForRevision, updateSingleHouse, updateSingleEmail } from '../store/actions/datasetActions'
import * as libCon from "../community-hats-js-library/Constants"
import { Button, Card, DatePicker, Input, Space, Spin, Table, Tag, TimePicker } from "antd";
import { Typography } from 'antd';
import { formatDate, parseISO } from 'date-fns';
import { formatDistanceShort, formatToIndiaTime, formatToIndiaTimeReadable } from '../community-hats-js-library/utils/dateFunctions';
import { getLastSync, getLatestHeartRateTimestamp } from '../community-hats-js-library/utils/fitbitFunctions';
import { getColorOfExpiry, getColorOfLastUpload, getTextOfExpiry, isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { SymbolForStatus } from '../community-hats-js-library/utils/devicesStatusFunctions';
import Modal from 'antd/es/modal/Modal';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { getDaysStyle, getEndDateStyle } from '../community-hats-js-library/utils/styleFunctions';
import { showError } from '../utils/generalFunctions';

const { Title, Text } = Typography;


export const DeviceStatusSection = ({ status, message }) => {

  return (
    <div style={{ paddingBottom: "18px", display: "flex", flexDirection: "column" }}>
      <Text style={{ marginBottom: "2px" }} strong>Collection Status: <SymbolForStatus status={status} /></Text>
      <Text>{libCon.MESSAGES_FOR_STATUS[message]}</Text>
    </div>
  )

}

export const CustomTag = ({ tagText, i }) => {
  return (<Tag color={libCon.TAG_COLORS[i % libCon.TAG_COLORS.length]}
    style={{
      marginLeft: "3px",
      marginRight: "3px"
    }}>
    {tagText}
  </Tag>)
}

export const IssueSection = ({ issue }) => {

  const [isModalOpen, setIsModalOpen] = useState(() => false)
  const [confirmLoading, setConfirmLoading] = useState(() => false)
  const [noteText, setNoteText] = useState(() => null)

  const localMarkIssueAsDone = async () => {
    setConfirmLoading(true)

    let status = await markIssueAsReadyForRevision(issue, noteText)

    if (status !== libCon.OK)
      showError(status)


    setIsModalOpen(false)
    setConfirmLoading(false)


  }


  return (<Card key={issue[libCon.ID]} headStyle={{ backgroundColor: "var(--primary-color-6-transparent)" }} type="inner" size="small" style={{ paddingtop: "0px" }} title={issue[libCon.ATF_PUBLIC_ID]}>
    <div className='verticalSection' style={{ paddingBottom: "15px" }}>
      <p style={{ margin: "1px" }}><strong>Tags</strong></p>
      <div className='horizontalSection'>
        {issue[libCon.ATF_TAGS].map((t, i) => <CustomTag key={i} tagText={t} i={i} />)}
      </div>

      <Text style={{ marginTop: "20px" }}><strong>Description</strong></Text>
      <Text style={{ marginTop: "1px" }}>{issue[libCon.ATF_DESCRIPTION]}</Text>

      <Text style={{ marginTop: "20px" }}><strong>Action</strong></Text>
      <Text style={{ marginTop: "1px" }}>{issue[libCon.ATF_ACTION]}</Text>

      <div style={{ marginTop: "30px" }}>
        {
          issue[libCon.ATF_STATUS] === libCon.ATF_PENDING
            ? <Button type="primary" onClick={() => setIsModalOpen(true)}>Mark as Done</Button>
            : <Text><strong>Issue Sent for Revision </strong><SymbolForStatus status={libCon.DEVICE_STATUS_OK} /></Text>
        }

        <Modal title={"Notes"}
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onOk={() => localMarkIssueAsDone()}
          okText={"Send for Revision"}
          confirmLoading={confirmLoading}
        >
          <div className='verticalSection' >
            <TextArea rows={3} value={noteText} onChange={e => setNoteText(e.target.value)} />
          </div>

        </Modal>
      </div>

    </div>
  </Card>)
}



export const SensorSection = ({ sensor }) => {

  // Updating Sensor
  const [updatingSensor, setUpdatingSensor] = useState(() => false)

  // Battery
  const [batteryDialogOpen, setBatteryDialogOpen] = useState(() => false)
  const [dateBatteryChange, setDateBatteryChange] = useState(() => formatDate(new Date(), "yyyy-MM-d"))
  const [timeBatteryChange, setTimeBatteryChange] = useState(() => formatDate(new Date(), "HH:mm"))
  const [isLoggingBatteryChange, setIsLoggingBatteryChange] = useState(false)



  const localLogBatteryChange = async (serial) => {
    setBatteryDialogOpen(false)
    setIsLoggingBatteryChange(true)
    let status = await logBatteryChange(serial, dateBatteryChange, timeBatteryChange)
    if (status !== libCon.OK)
      showError(status)

    setIsLoggingBatteryChange(false)

  }


  const refreshSensorFiles = async (sensorSerial) => {
    setUpdatingSensor(true)
    await updateSingleSensorFiles(sensorSerial)
    setUpdatingSensor(false)
  }



  const sensorFilesColumns = [
    {
      title: 'Uploaded',
      dataIndex: libCon.ATF_DATE_UPLOADED,
      key: libCon.ATF_DATE_UPLOADED,
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => parseISO(a[libCon.ATF_DATE_UPLOADED]) - parseISO(b[libCon.ATF_DATE_UPLOADED]),
      render: (val, record) => <p style={{ textAlign: 'center' }}>{formatToIndiaTimeReadable(val, false, false)}<br /> {formatDistanceShort(val)}</p>
    },
    {
      title: 'Start Date',
      dataIndex: libCon.ATF_START_DATE,
      key: libCon.ATF_START_DATE,
      align: 'center',
      render: (val, record) => <p style={{ textAlign: 'center' }}>{formatToIndiaTimeReadable(val, false, false)}<br /> {formatDistanceShort(val)}</p>
    },
    {
      title: 'End Date',
      dataIndex: libCon.ATF_END_DATE,
      key: libCon.ATF_END_DATE,
      align: 'center',
      render: (val, record) => <p style={{ textAlign: 'center', ...getEndDateStyle(val, record) }}>{formatToIndiaTimeReadable(val, false, false)}<br /> {formatDistanceShort(val)}</p>
    },
    {
      title: 'Days',
      dataIndex: libCon.ATF_SIZE_IN_DAYS,
      key: libCon.ATF_SIZE_IN_DAYS,
      align: 'center',
      render: (val, record) => <p style={{ textAlign: 'center', ...getDaysStyle(val, record) }}>{val}</p>
    },
  ];



  return (<Card key={sensor[libCon.ID]} headStyle={{ backgroundColor: "var(--primary-color-5-transparent)" }} type="inner" size="small" style={{ paddingtop: "0px" }} title={sensor[libCon.ATF_SERIAL]}>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingBottom: "15px" }}>
      {
        updatingSensor === true
          ? <div>
            <Spin />
          </div>
          : <Button type="primary" onClick={() => refreshSensorFiles(sensor[libCon.ATF_SERIAL])}>Refresh</Button>

      }
    </div>

    <DeviceStatusSection status={sensor[libCon.ATF_DEVICE_COLLECTION_STATUS]} message={sensor[libCon.ATF_DEVICE_COLLECTION_MESSAGE]} />
    <div style={{ marginBottom: "15px", display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <Text style={{ marginRight: "10px" }}><Text strong>Brand: </Text>{sensor[libCon.ATF_BRAND]}</Text>
      <Text style={{ marginLeft: "10px" }}><Text strong>Model: </Text>{sensor[libCon.ATF_MODEL]}</Text>
    </div>
    <div>
      <p style={{ margin: "1px" }}><strong>Placement Start: </strong>{formatToIndiaTime(sensor[libCon.ATF_START_DATE], false)} ({formatDistanceShort(sensor[libCon.ATF_START_DATE])})</p>
      <p style={{ margin: "1px" }}><strong>Latest Battery Change: </strong>{formatToIndiaTime(sensor[libCon.ATF_LATEST_BATTERY_CHANGE], false)} ({formatDistanceShort(sensor[libCon.ATF_LATEST_BATTERY_CHANGE])})</p>
      <div style={{ marginTop: "10px" }}>
        {
          isLoggingBatteryChange === true
            ? <div>
              <Spin />
            </div>
            : <Button type="primary" onClick={() => setBatteryDialogOpen(true)}>Log Battery Change</Button>

        }
      </div>
      {/* Creating multiple mpodals and only calling the last one */}
      <Modal title="Battery Change" open={batteryDialogOpen} onOk={() => localLogBatteryChange(sensor[libCon.ATF_SERIAL])} onCancel={() => setBatteryDialogOpen(false)} okText="Log Battery Change" cancelText="Cancel">
        <div className='verticalSection' >
          <Text>Date</Text>
          <DatePicker style={{ marginBottom: "10px" }} onChange={(d, dateString) => setDateBatteryChange(formatDate(new Date(dateString), "yyyy-MM-d"))} value={dayjs(dateBatteryChange)} />
          <Text>Time</Text>
          <TimePicker style={{ marginBottom: "10px" }} onChange={(time) => { setTimeBatteryChange(time.format("HH:mm")) }} value={dayjs(timeBatteryChange, "HH:mm")} format={"HH:mm"} />
        </div>
      </Modal>
    </div>
    <div style={{ paddingTop: "15px", marginBottom: "15px", display: "flex", flexDirection: "column" }}>
      <Text strong>Uploaded Files</Text>
      {
        sensor[libCon.ATF_RECEIVED_SENSOR_FILES].length === 0
          ? <Text>No files received in the last month</Text>
          : <Table dataSource={sensor[libCon.ATF_RECEIVED_SENSOR_FILES]} columns={sensorFilesColumns} size="small" pagination={{ pageSize: 5 }} />

      }
    </div>

  </Card>
  )
}

export const DataCollectionStatusCard = ({ participant }) => {

  const isCorrect = participant[libCon.ATF_HAS_ACTIVE_SENSORS] && participant[libCon.ATF_HAS_ACTIVE_WEARABLES] && participant[libCon.ATF_HAS_ACTIVE_PHONES]

  let activeSensors = participant[libCon.ATF_ACTIVE_SENSORS]
  let hasActiveSensors = participant[libCon.ATF_HAS_ACTIVE_SENSORS]



  let phone = participant[libCon.ATF_ACTIVE_PHONE]
  let wearable = participant[libCon.ATF_ACTIVE_WEARABLE]
  let email = participant[libCon.ATF_ACTIVE_EMAIL]
  let groundTeamIssues = participant[libCon.ATF_GROUND_TEAM_ISSUES] === undefined || participant[libCon.ATF_GROUND_TEAM_ISSUES] === null ? [] : participant[libCon.ATF_GROUND_TEAM_ISSUES]


  const [updatingPhone, setUpdatingPhone] = useState(() => false)
  const [updatingEmail, setUpdatingEmail] = useState(() => false)
  const [updatingParticipant, setUpdatingParticipant] = useState(() => false)
  const [updatingHouse, setUpdatingHouse] = useState(() => false)






  const refreshPhone = async (phoneSerial) => {
    setUpdatingPhone(true)
    await updateSinglePhone(phoneSerial)
    setUpdatingPhone(false)
  }


  const refreshEmail = async (emailId) => {


    setUpdatingEmail(true)

    // Grabs latest version from the airtable
    const records = await updateSingleEmail(emailId)

    const newEmail = records[emailId]

    let emailAdress = newEmail[libCon.ATF_EMAIL]
    let requestId = newEmail[libCon.ATF_FITBIT_REQUEST_ID]
    let token = newEmail[libCon.ATF_FITBIT_TOKEN]

    let [response, lastSynched] = await getLastSync(requestId, token)


    if (response === libCon.OK) {

      // Checks HeartRate
      let [fitbitHeartRateResponse, recentHeartRate] = await getLatestHeartRateTimestamp(requestId, token, lastSynched)

      // Updates Airtable
      if (fitbitHeartRateResponse === libCon.OK) {
        await updateAccountWithLastSynched(emailAdress, lastSynched, recentHeartRate)

      }


    }

    setUpdatingEmail(false)

  }


  const refreshParticipant = async () => {

    setUpdatingParticipant(true)
    await updateSingleParticipant(participant[libCon.ID])
    setUpdatingParticipant(false)

  }

  const refreshHouse = async () => {
    setUpdatingHouse(true)
    if (!isNullOrUndefined(participant[libCon.ATF_ACTIVE_HOUSE])) {

      await updateSingleHouse(participant[libCon.ATF_ACTIVE_HOUSE][libCon.ID])

    }
    setUpdatingHouse(false)

  }



  return (
    <div className='pageContainer'>
      <Card style={{ marginTop: "2%", border: isCorrect ? "4px solid var(--success-color-1)" : "4px solid var(--warning-color-1)" }}
        headStyle={{ backgroundColor: isCorrect ? "var(--success-color-1-transparent)" : "var(--warning-color-1-transparent)" }}
        title={participant[libCon.ATF_PUBLIC_ID]}>

        <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <p style={{ margin: "1px" }}><strong>Date Joined: </strong>{formatToIndiaTime(participant[libCon.ATF_JOINED_DATE], false)} ({formatDistanceShort(participant[libCon.ATF_JOINED_DATE])})</p>
          <p style={{ margin: "1px", color: getColorOfExpiry(participant[libCon.ATF_DAYS_UNTIL_EXPIRE]) }}><strong style={{ color: "black" }}>Next Collection: </strong>{getTextOfExpiry(participant[libCon.ATF_DAYS_UNTIL_EXPIRE])}</p>
          {
            isNullOrUndefined(participant[libCon.ATF_LATEST_COLLECTION_DATE])
              ? <p style={{ margin: "1px", color: "var(--warning-color-1)" }}><strong style={{ color: "black" }}>Last Collection: </strong>{`Never`}</p>
              : <p style={{ margin: "1px", color: getColorOfLastUpload(participant[libCon.ATF_DAYS_SINCE_LAST_HOUSE_VISIT]) }}><strong style={{ color: "black" }}>Last Collection: </strong>{`${formatToIndiaTime(participant[libCon.ATF_LATEST_COLLECTION_DATE], true)} (${formatDistanceShort(participant[libCon.ATF_LATEST_COLLECTION_DATE])})`}</p>
          }
        </div>

        {/* Issues */}
        <Card headStyle={{ backgroundColor: "var(--primary-color-6)" }} className='no-bottom-margin' type="inner" size="small" style={{ paddingtop: "0px" }} title="Issues">
          <div style={{ marginBottom: "3px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
            {
              groundTeamIssues.length === 0
                ? <Text style={{ marginBottom: "25px" }}><strong>No Pending Issues </strong><SymbolForStatus status={libCon.STATUS_OK} /></Text>
                : groundTeamIssues.map(iss => <IssueSection key={iss[libCon.ID]} issue={iss} />)
            }
          </div>

        </Card>


        {/* Intake Survey */}
        <Card headStyle={{ backgroundColor: "var(--primary-color-7)" }} className='no-bottom-margin' type="inner" size="small" style={{ paddingtop: "0px" }} title="Intake Surveys">
          <div style={{ marginBottom: "10px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
            <Text style={{ marginBottom: "5px" }}><strong>Participant Intake Form </strong></Text>
            <Text style={{ marginBottom: "10px" }}>{libCon.MESSAGES_FOR_STATUS[participant[libCon.ATF_PARTICIPANT_INTAKE_FORM][libCon.ATF_DEVICE_COLLECTION_MESSAGE]]}<SymbolForStatus status={participant[libCon.ATF_PARTICIPANT_INTAKE_FORM][libCon.ATF_DEVICE_COLLECTION_STATUS]} /></Text>
            <div style={{ marginBottom: "25px" }}>
              {
                updatingParticipant === true
                  ? <div>
                    <Spin />
                  </div>
                  : <Button type="primary" onClick={() => refreshParticipant()}>Refresh</Button>

              }
            </div>
            <Text style={{ marginBottom: "5px", marginTop: "30px" }}><strong>House Intake Form </strong></Text>
            <Text style={{ marginBottom: "10px" }}>{libCon.MESSAGES_FOR_STATUS[participant[libCon.ATF_HOUSE_INTAKE_FORM][libCon.ATF_DEVICE_COLLECTION_MESSAGE]]}<SymbolForStatus status={participant[libCon.ATF_HOUSE_INTAKE_FORM][libCon.ATF_DEVICE_COLLECTION_STATUS]} /></Text>
            <div style={{ paddingBottom: "30px" }}>
              {
                updatingHouse === true
                  ? <div>
                    <Spin />
                  </div>
                  : <Button type="primary" onClick={() => refreshHouse()}>Refresh</Button>

              }
            </div>
          </div>

        </Card>


        {/* Sensors */}
        <Card headStyle={{ backgroundColor: "var(--primary-color-5)" }} className='no-bottom-margin' type="inner" size="small" style={{ paddingtop: "0px" }} title="Bundle Sensors">
          <div style={{ marginBottom: "3px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
            {
              hasActiveSensors
                ? activeSensors.map(s => <SensorSection key={s[libCon.ATF_SERIAL]} sensor={s} />)
                : <div className='verticalSection' style={{ paddingBottom: "250px" }}>
                  <SymbolForStatus status={libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT} message={libCon.MESSAGE_INCOMPLETE_DEPLOYMENT} />
                  <Text type='danger'>Participant has no active sensors</Text>
                </div>
            }
          </div>

        </Card>

        {/* Phone */}
        <Card headStyle={{ backgroundColor: "var(--primary-color-1)" }} className='no-bottom-margin' type="inner" size="small" title="Location Tracker App">
          {
            phone === null
              ? <Text>Participant has no active phone placement</Text>
              : <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                <DeviceStatusSection status={phone[libCon.ATF_DEVICE_COLLECTION_STATUS]} message={phone[libCon.ATF_DEVICE_COLLECTION_MESSAGE]} />
                <Text style={{ marginBottom: "15px" }}><Text strong>Phone Serial: </Text>{phone[libCon.ATF_SERIAL]}</Text>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Text style={{ marginRight: "10px" }}><Text strong>Last Uploaded: </Text>{formatDistanceShort(phone[libCon.ATF_LAST_UPLOADED])}</Text>
                  <Text style={{ marginLeft: "10px" }}><Text strong>Latest Record: </Text>{formatDistanceShort(phone[libCon.ATF_LATEST_RECORD])}</Text>
                </div>
                <div style={{ marginTop: "5px", display: "flex", flexDirection: "row", justifyContent: "right" }}>
                  {
                    updatingPhone === true
                      ? <div>
                        <Spin />
                      </div>
                      : <Button type="primary" onClick={() => refreshPhone(phone[libCon.ATF_SERIAL])}>Refresh</Button>

                  }
                </div>
              </div>
          }
        </Card>

        {/* Wearable */}
        <Card headStyle={{ backgroundColor: "var(--primary-color-3)" }} className='no-bottom-margin' type="inner" size="small" title="Fitbit Device">
          {
            wearable === null
              ? <Text>Participant has no active Fitbit placement</Text>
              : <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                <DeviceStatusSection status={wearable[libCon.ATF_DEVICE_COLLECTION_STATUS]} message={wearable[libCon.ATF_DEVICE_COLLECTION_MESSAGE]} />
                <Text style={{ marginBottom: "15px" }}><Text strong>Fitbit Serial: </Text>{wearable[libCon.ATF_SERIAL]}</Text>
                <Text style={{ marginBottom: "15px" }}><Text strong>Email: </Text>{email[libCon.ATF_EMAIL]}</Text>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Text style={{ marginRight: "10px" }}><Text strong>Last Synched: </Text>{isNullOrUndefined(email[libCon.ATF_LAST_UPLOADED]) ? "Never" : `${formatToIndiaTimeReadable(email[libCon.ATF_LAST_UPLOADED], true, false)} (${formatDistanceShort(email[libCon.ATF_LAST_UPLOADED])})`}</Text>

                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Text style={{ marginRight: "10px" }}><Text strong>Last Heart Rate Upload: </Text>{isNullOrUndefined(email[libCon.ATF_LATEST_HEART_RATE_UPLOAD]) ? "Never" : `${formatToIndiaTimeReadable(email[libCon.ATF_LATEST_HEART_RATE_UPLOAD], true, false)} (${formatDistanceShort(email[libCon.ATF_LATEST_HEART_RATE_UPLOAD])})`}</Text>

                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
                  {
                    updatingEmail === true
                      ? <div>
                        <Spin />
                      </div>
                      : <Button type="primary" onClick={() => refreshEmail(email[libCon.ID])}>Refresh</Button>

                  }
                </div>
              </div>
          }
        </Card>

        {/* Intake Survey */}
        <Card headStyle={{ backgroundColor: "var(--primary-color-7)" }} className='no-bottom-margin' type="inner" size="small" style={{ paddingtop: "0px" }} title="Perceptual Survey">
          <div style={{ marginBottom: "3px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
            <Text style={{ marginBottom: "25px" }}><strong>{libCon.MESSAGES_FOR_STATUS[participant[libCon.ATF_PERCEPTUAL_SURVEY][libCon.ATF_DEVICE_COLLECTION_MESSAGE]]} </strong><SymbolForStatus status={participant[libCon.ATF_PERCEPTUAL_SURVEY][libCon.ATF_DEVICE_COLLECTION_STATUS]} /></Text>
          </div>

          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Text style={{ marginRight: "10px" }}><Text strong>Last Uploaded: </Text>{formatToIndiaTimeReadable(participant[libCon.ATF_LAST_UPLOADED_PERCEPTUAL_SURVEY], true)} ({formatDistanceShort(participant[libCon.ATF_LAST_UPLOADED_PERCEPTUAL_SURVEY])})</Text>
          </div>

          <div style={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
            {
              updatingParticipant === true
                ? <div>
                  <Spin />
                </div>
                : <Button type="primary" onClick={() => refreshParticipant()}>Refresh</Button>

            }
          </div>

        </Card>
      </Card>
    </div>
  )
}

function DashboardCollectionStatus() {


  // Participants
  const [status, participants] = RefActiveParticipantsWithAllPlacements()

  const [participantArray, setParticipantArray] = useState([])



  const [inputValue, setInputValue] = useState(() => null)
  const [filterText, setFilterText] = useState(() => null)

  useEffect(() => {

    if (participants !== null) {
      let parArray = Object.values(participants)
      if (filterText !== null && filterText !== undefined && filterText !== "")
        parArray = parArray.filter(ob => ob[libCon.ATF_PUBLIC_ID].toUpperCase().includes(filterText.toUpperCase()))

      parArray.sort((a, b) => new Date(b[libCon.ATF_JOINED_DATE]) - new Date(a[libCon.ATF_JOINED_DATE]));
      if (parArray.length > 0)
        parArray = parArray.slice(0, Math.min(10, parArray.length))

      setParticipantArray(parArray)
    }

  }, [participants, filterText])


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };



  return (
    <div className="mainContainer">
      <Title level={2}>{`Participant's Data Collection Status`}</Title>
      <Space direction="vertical" >
        <Space direction="horizontal" >
          <Button type="primary" onClick={() => refreshAllDatasets()}>Refresh All</Button>
          <Input placeholder="Filter" value={inputValue} onChange={handleInputChange} />
          <Button type="primary" onClick={() => setFilterText(inputValue)}>Filter</Button>
          <Button type="primary" onClick={() => { setInputValue(null); setFilterText(null) }} danger>Clear</Button>
        </Space>

      </Space>

      <div style={{ marginTop: "5%" }}>
        {
          status === libCon.STATUS_NOT_STARTED || status === libCon.LOADING || participants === null
            ? <Spin />
            : status === libCon.ERROR
              ? <div>Error</div>
              : <div>
                {
                  participantArray.length > 0
                    ? participantArray.map(par => <DataCollectionStatusCard key={par[libCon.ID]} participant={par} />)
                    : <Title level={3}>{`No records found`}</Title>
                }
              </div>
        }
      </div>
    </div>
  )
}

export default DashboardCollectionStatus