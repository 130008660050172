// Dataset Actions
import { setValue, updateObjectValue } from './generalStoreActions';
import * as libCon from "../../community-hats-js-library/Constants"
import * as air_fun from "../../community-hats-js-library/utils/airtableFunctions"
import { showError } from '../../utils/generalFunctions';


export const fetchParticipantData = async () => {
    fetchData(libCon.STATUS_DATASET_PARTICIPANTS, libCon.DATASET_PARTICIPANTS, air_fun.getAllParticipants)
}

export const fetchSensorData = async () => {
    fetchData(libCon.STATUS_DATASET_SENSORS, libCon.DATASET_SENSORS, air_fun.getAllSensors)
}

export const fetchSensorHousePlacementData = async () => {
    fetchData(libCon.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT, libCon.DATASET_SENSOR_HOUSE_PLACEMENT, air_fun.getAllSensorHousePlacement)
}

export const fetchHouseData = async () => {
    fetchData(libCon.STATUS_DATASET_HOUSES, libCon.DATASET_HOUSES, air_fun.getAllHouses)
}

export const fetchEmailData = async () => {
    fetchData(libCon.STATUS_DATASET_EMAILS, libCon.DATASET_EMAILS, air_fun.getAllEmails)
}

export const fetchParticipantPlacementData = async () => {
    fetchData(libCon.STATUS_DATASET_PARTICIPANT_PLACEMENT, libCon.DATASET_PARTICIPANT_PLACEMENT, air_fun.getAllParticipantPlacement)
}

export const fetchWearableData = async () => {
    fetchData(libCon.STATUS_DATASET_WEARABLES, libCon.DATASET_WEARABLES, air_fun.getAllWearables)
}

export const fetchWearablePlacementData = async () => {
    fetchData(libCon.STATUS_DATASET_WEARABLE_PLACEMENT, libCon.DATASET_WEARABLE_PLACEMENT, air_fun.getAllWearablePlacements)
}


export const fetchPhoneData = async () => {
    fetchData(libCon.STATUS_DATASET_PHONES, libCon.DATASET_PHONES, air_fun.getAllPhones)
}

export const fetchPhonePlacementData = async () => {
    fetchData(libCon.STATUS_DATASET_PHONE_PLACEMENT, libCon.DATASET_PHONE_PLACEMENT, air_fun.getAllPhonesPlacement)
}

export const fetchReceivedSensorFiles = async () => {
    fetchData(libCon.STATUS_DATASET_RECEIVED_SENSOR_FILES, libCon.DATASET_RECEIVED_SENSOR_FILES, air_fun.getAllReceivedSensorFiles)
}

export const fetchPendingIssues = async () => {
    fetchData(libCon.STATUS_DATASET_ISSUES, libCon.DATASET_ISSUES, air_fun.getAllPendingIssues)
}


export const fetchData = async (status_id, dataset_id, extractionMethod) => {

    setValue(status_id, libCon.LOADING)

    try {

        const [status, records] = await extractionMethod()

        if (status === libCon.OK) {
            setValue(dataset_id, records)
            setValue(status_id, libCon.OK)
        }
        else
            setValue(status_id, libCon.ERROR)



    } catch (error) {
        console.log(error)
        setValue(status_id, libCon.ERROR)
    }

}


export const updateSinglePhone = async (serial) => {

    try {

        let [status, newRecords] = await air_fun.getPhoneBySerial(serial)

        if (status === libCon.OK) {
            updateObjectValue(libCon.DATASET_PHONES, newRecords)

            return newRecords
        }
        else
            showError(status)


    } catch (error) {
        console.log(error)
        showError(error)
    }
}

export const updateSingleAirtableObject = async (internalId, getFun, datasetId) => {
    try {

        let [status, newRecords] = await getFun(internalId)

        if (status === libCon.OK) {
            updateObjectValue(datasetId, newRecords)

            return newRecords
        }
        else
            showError(status)


    } catch (error) {
        console.log(error)
        showError(error)
    }
}

export const updateSingleParticipant = async (internalId) => {
    return await updateSingleAirtableObject(internalId, air_fun.getParticipant, libCon.DATASET_PARTICIPANTS)
}

export const updateSingleHouse = async (internalId) => {
    return await updateSingleAirtableObject(internalId, air_fun.getHouse, libCon.DATASET_HOUSES)
}


export const updateSingleWerablePlacements = async (internalId) => {
    return await updateSingleAirtableObject(internalId, air_fun.getWearablePlacement, libCon.DATASET_WEARABLE_PLACEMENT)
}


export const updateSingleEmail = async (internalId) => {
    return await updateSingleAirtableObject(internalId, air_fun.getEmail, libCon.DATASET_EMAILS)
}



export const updateSingleSensorFiles = async (serial) => {

    try {

        // Update received sensor files
        let [recordStatus, newRecords] = await air_fun.getReceivedSensorFilesBySerial(serial)

        if (recordStatus !== libCon.OK) {
            showError(recordStatus)
            return
        }

        updateObjectValue(libCon.DATASET_RECEIVED_SENSOR_FILES, newRecords)
        // Update sensor
        let [sensorStatus, newSensorRecord] = await air_fun.getSensorBySerial(serial)

        if (sensorStatus !== libCon.OK) {
            showError(sensorStatus)
            return
        }

        updateObjectValue(libCon.DATASET_SENSORS, newSensorRecord)

        return newRecords

    } catch (error) {
        console.log(error)
    }
}



export const updateAccountWithLastSynched = async (email, lastSynched, recentHeartRate) => {

    try {

        // Update received sensor files
        let [status, newRecords] = await air_fun.updateAccountLastSynched(email, lastSynched, recentHeartRate)

        if (status === libCon.OK) {
            updateObjectValue(libCon.DATASET_EMAILS, newRecords)

            return newRecords
        }
        else
            showError(status)


    } catch (error) {
        console.log(error)
        showError(error)
    }
}


export const logBatteryChange = async (serial, date, time) => {
    try {

        // Update received sensor files
        let [status, records] = await air_fun.logBatteryChange(serial, date, time)

        if (status === libCon.OK)
            updateObjectValue(libCon.DATASET_SENSORS, records)

        return status


    } catch (error) {
        console.log(error)
        return libCon.ERROR
    }
}


export const markIssueAsDone = async (issue, noteText) => {
    try {

        // Update received sensor files
        let [status, records] = await air_fun.markIssueAsDone(issue, noteText)

        if (status === libCon.OK)
            updateObjectValue(libCon.DATASET_ISSUES, records)

        return status


    } catch (error) {
        console.log(error)
        return libCon.ERROR
    }

}

export const markIssueAsReadyForRevision = async (issue, noteText) => {
    try {

        // Update received sensor files
        let [status, records] = await air_fun.markIssueAsReadyForRevision(issue, noteText, libCon.DASHBOARD)

        if (status === libCon.OK)
            updateObjectValue(libCon.DATASET_ISSUES, records)

        return status


    } catch (error) {
        console.log(error)
        return libCon.ERROR
    }

}

