// Data Manipulation Functions
import { addDays, eachDayOfInterval, parseISO, startOfDay } from 'date-fns';
import { filterObject } from '../hooks/GeneralStoreHooks';
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants'
import * as dt_fun from '../community-hats-js-library/utils/dateFunctions';
import * as ds_fun from '../community-hats-js-library/utils/devicesStatusFunctions';
import { getExpiryDaysByBrand } from '../community-hats-js-library/utils/sensorFunctions';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';



// Only SEWA participants
export const filterOnlySEWA = ({ allParticipants }) => {
    if (allParticipants === null)
        return null

    let participants = filterObject(allParticipants, pa => pa[libCon.ATF_TYPE] === "SEWA" && !(pa[libCon.ATF_PUBLIC_ID].toUpperCase().includes("TEST")))
    return participants
}

export const filterNotDevelopment = ({ allDevices, allParticipants }) => {
    if (isNullOrUndefined(allDevices))
        return null

    let devices = filterObject(allDevices, de => !(libCon.ATF_DEVELOPMENT in de) || de[libCon.ATF_DEVELOPMENT] === false)

    devices = filterObject(devices, de => {

        let pp = de[libCon.ATF_ACTIVE_PLACEMENT]

        if (!isNullOrUndefined(pp) &&
            allParticipants[pp[libCon.ATF_PARTICIPANT][0]][libCon.ATF_TYPE] === "SEWA" &&
            !(allParticipants[pp[libCon.ATF_PARTICIPANT][0]][libCon.ATF_PUBLIC_ID].toUpperCase().includes("TEST")))
            return true
        else
            return false
    })


    return devices

}

export const filterOnlyActive = ({ participants }) => {
    if (participants === null)
        return null
    let newParticipants = filterObject(participants, pa => pa[libCon.ATF_ACTIVE] === "Yes")
    return newParticipants
}


export const attachSensorPlacementToParticipants = ({ participants, sensors, sensorPlacements }) => {
    if (participants === null || sensors === null || sensorPlacements === null ||
        participants === undefined || sensors === undefined || sensorPlacements === undefined)
        return participants

    //console.log(sensorPlacements)

    Object.values(participants).forEach(par => {

        par[libCon.ATF_HAS_ACTIVE_SENSORS] = false
        par[libCon.ATF_TOTAL_ACTIVE_SENSORS] = 0
        if (libCon.ATF_SENSOR_PLACEMENTS in par) {
            par[libCon.ATF_SENSOR_PLACEMENTS] = par[libCon.ATF_SENSOR_PLACEMENTS].map(id => {
                return ({ ...sensorPlacements[id], [libCon.ATF_SENSOR]: { ...sensors[sensorPlacements[id][libCon.ATF_SENSOR]] } })
            })


            par[libCon.ATF_ACTIVE_SENSORS] = []
            par[libCon.ATF_SENSOR_PLACEMENTS].filter(sp => sp[libCon.ATF_ACTIVE] === libCon.YES).forEach(sp => {
                let s = sp[libCon.ATF_SENSOR]
                s[libCon.ATF_START_DATE] = sp[libCon.ATF_START_DATE]
                par[libCon.ATF_ACTIVE_SENSORS].push(s)
            })

            par[libCon.ATF_HAS_ACTIVE_SENSORS] = par[libCon.ATF_ACTIVE_SENSORS].length > 0
            par[libCon.ATF_TOTAL_ACTIVE_SENSORS] = par[libCon.ATF_ACTIVE_SENSORS].length

        }

    })

    return participants

}


export const attachHousesToParticipants = ({ participants, houses }) => {
    if (participants === null || houses === null ||
        participants === undefined || houses === undefined)
        return participants


    Object.values(participants).forEach(par => {

        if (libCon.ATF_ACTIVE_HOUSE in par)
            par[libCon.ATF_ACTIVE_HOUSE] = houses[par[libCon.ATF_ACTIVE_HOUSE][0]]
        else
            par[libCon.ATF_ACTIVE_HOUSE] = null

    })

    return participants

}


export const attachSensorPlacementToSensors = ({ sensors, sensorPlacements }) => {
    if (isNullOrUndefined(sensors) || isNullOrUndefined(sensorPlacements))
        return sensors


    Object.values(sensors).forEach(sen => {

        sen[libCon.ATF_ACTIVE_PLACEMENT] = null

        if (libCon.ATF_SENSOR_PLACEMENT in sen) {
            sen[libCon.ATF_SENSOR_PLACEMENT] = sen[libCon.ATF_SENSOR_PLACEMENT].map(id => {
                return ({ ...sensorPlacements[id], [libCon.ATF_SENSOR]: { ...sensors[sensorPlacements[id][libCon.ATF_SENSOR]] } })
            })


            sen[libCon.ATF_ACTIVE_PLACEMENT] = sen[libCon.ATF_SENSOR_PLACEMENT].find(sp => sp[libCon.ATF_ACTIVE] === libCon.YES)

        }
        else
            sen[libCon.ATF_SENSOR_PLACEMENT] = []

    })

    return sensors

}

export const attachPhonePlacement = ({ participants, phones, phonePlacement }) => {
    if (participants === null || phones === null || phonePlacement === null ||
        participants === undefined || phones === undefined || phonePlacement === undefined)
        return participants


    Object.values(participants).forEach(par => {

        par[libCon.ATF_HAS_ACTIVE_PHONES] = false
        if (libCon.ATF_PHONE_PLACEMENTS in par) {
            par[libCon.ATF_PHONE_PLACEMENTS] = par[libCon.ATF_PHONE_PLACEMENTS].map(id => {
                return ({ ...phonePlacement[id], [libCon.ATF_PHONE]: { ...phones[phonePlacement[id][libCon.ATF_PHONE]] } })
            })

            par[libCon.ATF_HAS_ACTIVE_PHONES] = par[libCon.ATF_PHONE_PLACEMENTS].some(s => s[libCon.ATF_ACTIVE] === libCon.YES)
            par[libCon.ATF_ACTIVE_PHONE] = null
            if (par[libCon.ATF_HAS_ACTIVE_PHONES])
                par[libCon.ATF_ACTIVE_PHONE] = par[libCon.ATF_PHONE_PLACEMENTS].filter(pp => pp[libCon.ATF_ACTIVE] === libCon.YES)[0][libCon.ATF_PHONE]

        }

    })

    return participants

}


export const attachWearablePlacement = ({ participants, wearables, wearablePlacement, emails }) => {
    if (participants === null || wearables === null || wearablePlacement === null ||
        participants === undefined || wearables === undefined || wearablePlacement === undefined)
        return participants


    Object.values(participants).forEach(par => {

        par[libCon.ATF_HAS_ACTIVE_WEARABLES] = false
        if (libCon.ATF_WEARABLE_PLACEMENT in par) {
            par[libCon.ATF_WEARABLE_PLACEMENT] = par[libCon.ATF_WEARABLE_PLACEMENT].map(id => {
                return ({ ...wearablePlacement[id], [libCon.ATF_WEARABLE]: { ...wearables[wearablePlacement[id][libCon.ATF_WEARABLE]] } })
            })

            par[libCon.ATF_HAS_ACTIVE_WEARABLES] = !isNullOrUndefined(par[libCon.ATF_ACTIVE_WEARABLE])

            if (par[libCon.ATF_HAS_ACTIVE_WEARABLES])
                par[libCon.ATF_ACTIVE_WEARABLE] = wearables[par[libCon.ATF_ACTIVE_WEARABLE][0]]
            else
                par[libCon.ATF_ACTIVE_WEARABLE] = null


            par[libCon.ATF_HAS_ACTIVE_EMAILS] = !isNullOrUndefined(par[libCon.ATF_ACTIVE_EMAIL])
            if (par[libCon.ATF_HAS_ACTIVE_EMAILS])
                par[libCon.ATF_ACTIVE_EMAIL] = emails[par[libCon.ATF_ACTIVE_EMAIL][0]]
            else
                par[libCon.ATF_ACTIVE_EMAIL] = null


        }



    })

    return participants

}


export const attachReceivedSensorFiles = ({ participants, receivedSensorFiles }) => {
    if (participants === null || receivedSensorFiles === null ||
        participants === undefined || receivedSensorFiles === undefined)
        return participants


    Object.values(participants).forEach(par => {
        if (libCon.ATF_SENSOR_PLACEMENTS in par) {
            par[libCon.ATF_SENSOR_PLACEMENTS].forEach(sp => {

                if (libCon.ATF_RECEIVED_SENSOR_FILES in sp[libCon.ATF_SENSOR])
                    sp[libCon.ATF_SENSOR][libCon.ATF_RECEIVED_SENSOR_FILES] = sp[libCon.ATF_SENSOR][libCon.ATF_RECEIVED_SENSOR_FILES].filter(rsk => rsk in receivedSensorFiles).map(rsk => receivedSensorFiles[rsk])
                else
                    sp[libCon.ATF_SENSOR][libCon.ATF_RECEIVED_SENSOR_FILES] = []

            })
        }

    })

    return participants

}



export const attachIssuesToParticipant = ({ participants, issues }) => {
    if (participants === null || issues === null ||
        participants === undefined || issues === undefined)
        return participants


    Object.values(participants).forEach(par => {
        if (libCon.ATF_ISSUES in par) {
            par[libCon.ATF_ISSUES] = par[libCon.ATF_ISSUES].filter(i => i in issues).map(i => issues[i])
            par[libCon.ATF_GROUND_TEAM_ISSUES] = par[libCon.ATF_ISSUES].filter(i => i[libCon.ATF_RESPONSIBLE] === libCon.ATF_GROUND_TEAM)
        }

        else {
            par[libCon.ATF_ISSUES] = []
            par[libCon.ATF_GROUND_TEAM_ISSUES] = []
        }


    })

    return participants

}


export const attachParticipantPlacementsToParticipant = ({ participants, participantPlacements }) => {
    if (participants === null || participantPlacements === null ||
        participants === undefined || participantPlacements === undefined)
        return participants


    Object.values(participants).forEach(par => {
        if (libCon.ATF_PARTICIPANT_PLACEMENT in par) {
            par[libCon.ATF_PARTICIPANT_PLACEMENT] = par[libCon.ATF_PARTICIPANT_PLACEMENT].filter(i => i in participantPlacements).map(i => participantPlacements[i])
        }

        else {
            par[libCon.ATF_PARTICIPANT_PLACEMENT] = []
        }


    })

    return participants

}




export const computeDaysUntilExpiry = ({ participants }) => {

    if (participants === null)
        return (participants)

    const now = new Date()

    Object.values(participants).forEach((par, i) => {

        par[libCon.ATF_DAYS_UNTIL_EXPIRE] = Infinity

        if (libCon.ATF_SENSOR_PLACEMENTS in par) {
            let arr = par[libCon.ATF_SENSOR_PLACEMENTS].filter(sp => sp[libCon.ATF_ACTIVE] === libCon.YES).map(sp => {
                let sns = sp[libCon.ATF_SENSOR]
                let expDays = getExpiryDaysByBrand(sns[libCon.ATF_BRAND])


                let mostRecentDate = new Date(sp[libCon.ATF_START_DATE])


                // Battery
                if (!isNullOrUndefined(sns) && libCon.ATF_LATEST_BATTERY_CHANGE in sns && new Date(sns[libCon.ATF_LATEST_BATTERY_CHANGE]) > mostRecentDate)
                    mostRecentDate = new Date(sns[libCon.ATF_LATEST_BATTERY_CHANGE])

                if (!isNullOrUndefined(sns) && libCon.ATF_LATEST_RECORD in sns && new Date(sns[libCon.ATF_LATEST_RECORD]) > mostRecentDate)
                    mostRecentDate = new Date(sns[libCon.ATF_LATEST_RECORD])


                return expDays - dt_fun.getDaysBetweenDates(mostRecentDate, now)

            })

            if (arr.length > 0)
                par[libCon.ATF_DAYS_UNTIL_EXPIRE] = Math.min(...arr)

        }

        if (libCon.ATF_LAST_UPLOADED_WEARABLE in par) {
            let wearableDaysUntill = libCon.INPIRE_3_CAPACITY - dt_fun.getDaysBetweenDates(par[libCon.ATF_LAST_UPLOADED_WEARABLE], now)

            if (wearableDaysUntill < par[libCon.ATF_DAYS_UNTIL_EXPIRE])
                par[libCon.ATF_DAYS_UNTIL_EXPIRE] = wearableDaysUntill
        }

    })

    return participants


}



export const computeDaysSinceLastUpload = ({ participants }) => {

    if (participants === null)
        return (participants)

    const now = new Date()

    Object.values(participants).forEach((par, i) => {



        par[libCon.ATF_LATEST_COLLECTION_DATE] = new Date(par[libCon.ATF_DATA_START])


        // By Sensors
        if (libCon.ATF_SENSOR_PLACEMENTS in par) {

            // // Days since last Upload
            // let arr = par[libCon.ATF_SENSOR_PLACEMENTS].filter(sp => sp[libCon.ATF_ACTIVE] === libCon.YES).map(sp => {
            //     let sns = sp[libCon.ATF_SENSOR]

            //     let daysSinceLastUpload = dt_fun.getDaysBetweenDates(sp[libCon.ATF_START_DATE], now)
            //     if (sns !== undefined && libCon.ATF_LAST_UPLOADED in sns)
            //         daysSinceLastUpload = Math.min(daysSinceLastUpload, dt_fun.getDaysBetweenDates(sns[libCon.ATF_LAST_UPLOADED], now))

            //     return daysSinceLastUpload

            // })

            // if (arr.length > 0)
            //     par[libCon.ATF_DAYS_SINCE_LAST_HOUSE_VISIT] = Math.min(...arr)

            // Last collection Date
            let arrDates = par[libCon.ATF_SENSOR_PLACEMENTS].filter(sp => sp[libCon.ATF_ACTIVE] === libCon.YES).map(sp => {
                let sns = sp[libCon.ATF_SENSOR]

                let lastCollectionDate = new Date(sp[libCon.ATF_START_DATE])
                if (sns !== undefined && libCon.ATF_LAST_UPLOADED in sns)
                    lastCollectionDate = Math.max(lastCollectionDate, new Date(sns[libCon.ATF_LAST_UPLOADED]))

                return lastCollectionDate

            })


            if (arrDates.length > 0)
                par[libCon.ATF_LATEST_COLLECTION_DATE] = Math.max(...arrDates)

        }

        // By Perceptual Survey
        if (libCon.ATF_LAST_UPLOADED_PERCEPTUAL_SURVEY in par) {

            let lastSurvey = new Date(par[libCon.ATF_LAST_UPLOADED_PERCEPTUAL_SURVEY])

            if (lastSurvey > par[libCon.ATF_LATEST_COLLECTION_DATE]) {
                par[libCon.ATF_LATEST_COLLECTION_DATE] = lastSurvey

            }

        }

        par[libCon.ATF_DAYS_SINCE_LAST_HOUSE_VISIT] = dt_fun.getDaysBetweenDates(par[libCon.ATF_LATEST_COLLECTION_DATE], now)

    })

    return participants


}



export const computeDeviceCollectionStatus = ({ participants }) => {

    if (participants === null)
        return (participants)



    Object.values(participants).forEach((par, i) => {

        // Participant Intake Survey
        let [participantIntakeStatus, participantIntakeMessage] = ds_fun.getIntakeFormStatus(par[libCon.ATF_INTAKE_FORM_STATUS])

        par[libCon.ATF_PARTICIPANT_INTAKE_FORM] = {
            [libCon.ATF_DEVICE_COLLECTION_STATUS]: participantIntakeStatus,
            [libCon.ATF_DEVICE_COLLECTION_MESSAGE]: participantIntakeMessage
        }

        let [houseIntakeStatus, houseIntakeMessage] = isNullOrUndefined(par[libCon.ATF_ACTIVE_HOUSE]) ? [libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT, libCon.MESSAGE_INCOMPLETE_DEPLOYMENT] : ds_fun.getIntakeFormStatus(par[libCon.ATF_ACTIVE_HOUSE][libCon.ATF_INTAKE_FORM_STATUS])
        par[libCon.ATF_HOUSE_INTAKE_FORM] = {
            [libCon.ATF_DEVICE_COLLECTION_STATUS]: houseIntakeStatus,
            [libCon.ATF_DEVICE_COLLECTION_MESSAGE]: houseIntakeMessage
        }

        // All forms
        let [allIntakeFormStatus, allIntakeFormMessage] = ds_fun.getAllIntakeFormsStatus(participantIntakeStatus, participantIntakeMessage, houseIntakeStatus, houseIntakeMessage)

        par[libCon.ATF_ALL_INTAKE_FORMS] = {
            [libCon.ATF_DEVICE_COLLECTION_STATUS]: allIntakeFormStatus,
            [libCon.ATF_DEVICE_COLLECTION_MESSAGE]: allIntakeFormMessage
        }


        // Perceptual Survey
        let [perceptualStatus, perceptualMessage] = ds_fun.getPerceptualSurveyStatus(par[libCon.ATF_LAST_UPLOADED_PERCEPTUAL_SURVEY], par[libCon.ATF_LATEST_COLLECTION_DATE])

        par[libCon.ATF_PERCEPTUAL_SURVEY] = {
            [libCon.ATF_DEVICE_COLLECTION_STATUS]: perceptualStatus,
            [libCon.ATF_DEVICE_COLLECTION_MESSAGE]: perceptualMessage
        }

        // Phone
        if (par[libCon.ATF_HAS_ACTIVE_PHONES]) {
            // if(par[libCon.AT_SEWA_ID] === "20220331000367")
            //     console.log("YES")

            let [status, message] = ds_fun.getPhonePlacementStatus(par[libCon.ATF_ACTIVE_PHONE][libCon.ATF_LAST_UPLOADED], par[libCon.ATF_LATEST_COLLECTION_DATE])
            par[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_STATUS] = status
            par[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_MESSAGE] = message

        }

        // Wearable
        if (par[libCon.ATF_HAS_ACTIVE_WEARABLES]) {
            // if(par[libCon.AT_SEWA_ID] === "20220331000367")
            //     console.log("YES")

            let [status, message] = ds_fun.getWearablePlacementStatus(par[libCon.ATF_ACTIVE_EMAIL][libCon.ATF_LAST_UPLOADED], par[libCon.ATF_ACTIVE_EMAIL][libCon.ATF_LATEST_HEART_RATE_UPLOAD], par[libCon.ATF_LATEST_COLLECTION_DATE])
            par[libCon.ATF_ACTIVE_WEARABLE][libCon.ATF_DEVICE_COLLECTION_STATUS] = status
            par[libCon.ATF_ACTIVE_WEARABLE][libCon.ATF_DEVICE_COLLECTION_MESSAGE] = message

        }

        // Sensors
        if (par[libCon.ATF_HAS_ACTIVE_SENSORS]) {


            // if(par[libCon.AT_SEWA_ID] === "20240226038551")
            //     console.log("YES")

            par[libCon.ATF_SENSOR_PLACEMENTS].filter(sp => sp[libCon.ATF_ACTIVE] === libCon.YES).forEach(sp => {

                // if(sp[libCon.AT_SENSOR][libCon.AT_SERIAL] === "SY51D40408730")
                //     console.log("Yes")

                let [status, message] = ds_fun.getSensorPlacementStatus(sp[libCon.ATF_SENSOR][libCon.ATF_BRAND], sp[libCon.ATF_SENSOR][libCon.ATF_MODEL], sp[libCon.ATF_SENSOR][libCon.ATF_RECEIVED_SENSOR_FILES], par[libCon.ATF_LATEST_COLLECTION_DATE], sp[libCon.ATF_START_DATE], sp[libCon.ATF_SENSOR][libCon.ATF_LATEST_BATTERY_CHANGE])
                sp[libCon.ATF_SENSOR][libCon.ATF_DEVICE_COLLECTION_STATUS] = status
                sp[libCon.ATF_SENSOR][libCon.ATF_DEVICE_COLLECTION_MESSAGE] = message


            })

        }

        // For all sensors
        let [sensorStatus, sensorMessage] = ds_fun.getUnifyingStatusAndMessageForSensors(par[libCon.ATF_ACTIVE_SENSORS])


        par[libCon.AT_SENSORS_COLLECTION_STATUS] = sensorStatus
        par[libCon.AT_SENSORS_COLLECTION_MESSAGE] = sensorMessage

        // For Participant
        let [participantStatus, participantMessage] = ds_fun.getUnifyingStatusAndMessageForParticipant(par)
        par[libCon.AT_COLLECTION_STATUS] = participantStatus
        par[libCon.AT_COLLECTION_MESSAGE] = participantMessage

    })

    return participants


}


export const computeParticipantsByDate = (participants) => {
    return computeObjectsByDate(participants, libCon.ATF_PARTICIPANT_PLACEMENT)
}


export const computeSensorsByDate = (sensors) => {

    return computeObjectsByDate(sensors, libCon.ATF_SENSOR_PLACEMENT)
}


export const computeObjectsByDate = (objects, placementString) => {


    let summaryDeployment = {}
    let summaryRemoval = {}

    let minDate = null


    Object.values(objects).forEach((sen, i) => {

        // iterates over the placements
        sen[placementString].forEach((placement, j) => {

            let start_date = startOfDay(parseISO(placement[libCon.ATF_START_DATE])).getTime()
            let end_date = isNullOrUndefined(placement[libCon.ATF_END_DATE]) ? null : startOfDay(parseISO(placement[libCon.ATF_END_DATE])).getTime()

            // Deployment
            if (!(start_date in summaryDeployment))
                summaryDeployment[start_date] = 0

            summaryDeployment[start_date] += 1

            // Removal
            if (!isNullOrUndefined(end_date)) {
                if (!(end_date in summaryRemoval))
                    summaryRemoval[end_date] = 0

                summaryRemoval[end_date] -= 1
            }



            if (minDate === null)
                minDate = start_date
            else
                minDate = Math.min(start_date, minDate)

        })

    })

    const dates = eachDayOfInterval({ start: new Date(minDate), end: addDays(new Date(), 1) });


    // Iterate over the dates
    const deployment = dates.map(date => {
        if (date.getTime() in summaryDeployment)
            return (summaryDeployment[date.getTime()])

        return 0
    });

    let val = 0
    const cumulative = dates.map(date => {

        if (date.getTime() in summaryDeployment)
            val += summaryDeployment[date.getTime()]

        if (date.getTime() in summaryRemoval)
            val += summaryRemoval[date.getTime()]

        return val
    });


    return dates.map((d, i) => { return ({ [locCon.DATE]: d, [locCon.VALUE]: deployment[i], [locCon.CUMULATIVE_VALUE]: cumulative[i] }) })

}


export const extractCoordinatesFromActivePlacements = (sensors) => {
    let coordinates = []

    Object.values(sensors).forEach(sen => {
        sen[libCon.ATF_SENSOR_PLACEMENT].forEach(sp => {
            if (sp[libCon.ATF_ACTIVE] === libCon.YES)
                coordinates.push([sp[libCon.ATF_LATITUDE], sp[libCon.ATF_LONGITUDE], sen[libCon.ATF_BRAND]])
        })
    })

    return coordinates

}