import './App.css';

import { RefCurrentPage } from './hooks/NavigationHooks';
import * as locCon from './LocalConstants';
import * as libCon from './community-hats-js-library/Constants';


import DashboardSelect from './pages/DashboardSelect';
import DashboardExpiryParticipants from './pages/DashboardExpiryParticipants';
import { useEffect, useState } from 'react';

import { Button, Typography, Input, Space } from 'antd';
import DashboardDeploymentProgress from './pages/DashboardDeploymentProgress';
import DashboardDeploymentStatus from './pages/DashboardDeploymentStatus';
import DashboardDataStreamQuality from './pages/DashboardDataStreamQuality';
import DashboardCollectionStatus from './pages/DashboardCollectionStatus';
import DashboardSingleCollectionStatus from './pages/DashboardSingleCollectionStatus';
import { fetchEmailData, fetchHouseData, fetchParticipantData, fetchParticipantPlacementData, fetchPendingIssues, fetchPhoneData, fetchPhonePlacementData, fetchReceivedSensorFiles, fetchSensorData, fetchSensorHousePlacementData, fetchWearableData, fetchWearablePlacementData } from './store/actions/datasetActions'
import { RefIsLoggedIn } from './hooks/AuthenticationHooks';
import { setStoredValue } from './hooks/GeneralStoreHooks';
import { formatISO } from 'date-fns';
// import { getLastSync, getLatestHeartRateTimestamp } from './community-hats-js-library/utils/fitbitFunctions';
// import { isNullOrUndefined, sleep } from './community-hats-js-library/utils/generalFunctions';


const { Text } = Typography;

function App() {

  // Navigation
  const currentPage = RefCurrentPage();

  // Password
  const [showMessage, setShowMessage] = useState(false)
  const [password, setPassword] = useState(null)

  // Authentication
  const isLoggedIn = RefIsLoggedIn()

  // const emails = RefStoredValue(libCon.DATASET_EMAILS)


  // const updateAll = async () => {

  //   if (!isNullOrUndefined(emails)) {
  //     for (const emailId in emails) {

  //       if (emails[emailId][libCon.ATF_ACTIVE] !== "Yes" || !(libCon.ATF_FITBIT_TOKEN in emails[emailId]))
  //         continue

  //       console.log(emailId)
  //       // Grabs latest version from the airtable
  //       const records = await updateSingleEmail(emailId)

  //       const newEmail = records[emailId]

  //       let emailAdress = newEmail[libCon.ATF_EMAIL]
  //       let requestId = newEmail[libCon.ATF_FITBIT_REQUEST_ID]
  //       let token = newEmail[libCon.ATF_FITBIT_TOKEN]

  //       let [response, lastSynched] = await getLastSync(requestId, token)


  //       if (response === libCon.OK) {

  //         // Checks HeartRate
  //         let [fitbitHeartRateResponse, recentHeartRate] = await getLatestHeartRateTimestamp(requestId, token, lastSynched)


  //         console.log(recentHeartRate)
  //         // Updates Airtable
  //         if (fitbitHeartRateResponse === libCon.OK) {
  //           await updateAccountWithLastSynched(emailAdress, lastSynched, recentHeartRate)

  //         }

  //       }

  //       await sleep(1500)
  //     }
  //   }

  //   console.log("FInished")

  // }



  useEffect(() => {
    fetchParticipantData()
    fetchHouseData()
    fetchEmailData()
    fetchParticipantPlacementData()
    fetchSensorData()
    fetchSensorHousePlacementData()
    fetchPhoneData()
    fetchPhonePlacementData()
    fetchWearableData()
    fetchWearablePlacementData()
    fetchReceivedSensorFiles()
    fetchPendingIssues()
  }, [])


  const logIn = () => {


    if (password !== null && password !== undefined && password.toUpperCase() === locCon.PASSWORD.toUpperCase()) {
      setStoredValue(locCon.IS_LOGGED_IN, formatISO(new Date()))
      setShowMessage(false)
    }
    else
      setShowMessage(true)

  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      logIn()
    }
  }

  // Uses a function through switch to render
  const renderDesktopComponents = (page) => {

    switch (page) {
      case locCon.PAGE_START:
        return (<DashboardSelect />)
      case locCon.PAGE_DASHBOARD_EXPIRY_PARTICIPANTS:
        return (<DashboardExpiryParticipants />)
      case locCon.PAGE_DASHBOARD_DEPLOYMENT_STATUS:
        return (<DashboardDeploymentStatus />)
      case locCon.PAGE_DASHBOARD_COLLECTION_STATUS:
        return (<DashboardCollectionStatus />)
      case locCon.PAGE_DASHBOARD_DEPLOYMENT_PROGRESS:
        return (<DashboardDeploymentProgress />)
      case locCon.PAGE_DASHBOARD_DATA_STREAM_QUALITY:
        return (<DashboardDataStreamQuality />)
      case locCon.PAGE_DASHBOARD_SINGLE_COLLECTION_STATUS:
        return (<DashboardSingleCollectionStatus />)
      default:
        return (<DashboardSelect />);
    }
  }



  return (
    <div>
      {/* <Button type='primary' onClick={() => updateAll()} >{"Update"}</Button> */}

      {
        locCon.DEBUG
          ? <p style={{ margin: " 0px" }}>DEBUG IS ON</p>
          : <div></div>
      }
      {
        libCon.DEVELOPMENT
          ? <p style={{ margin: " 0px" }}>Running on Development</p>
          : <div></div>
      }
      <div className="App">
        {
          libCon.DEVELOPMENT || locCon.DEBUG || isLoggedIn
            ? renderDesktopComponents(currentPage)

            : <div onKeyPress={handleKeyPress}> <Space direction='vertical'>
              <Input.Password onChange={(e) => setPassword(e.target.value)} value={password} placeholder={"Password"} />
              <Button type='primary' onClick={() => logIn()} >{"Enter"}</Button>
              {showMessage
                ? <Text type="danger">Password is not correct</Text>
                : <div></div>
              }
            </Space>
            </div>
        }
      </div>
    </div>
  );
}

export default App;
